import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import logo from "../assets/favicon-152.png";
import backgroundImage from "../assets/login-hero-bg.webp";

export const HeroBanner: React.FC = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/chat",
      },
      authorizationParams: {
        prompt: "login",
      },
    });
  };

  const handleSignUp = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/chat",
      },
      authorizationParams: {
        prompt: "login",
        screen_hint: "signup",
      },
    });
  };

  return (
    <div
      className="hero-banner relative flex items-center justify-center"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {/* Overlay to adjust background image opacity */}
      <div className="absolute inset-0 bg-slate-700 opacity-50"></div>

      {/* Content */}
      <div className="relative z-10 flex flex-col items-center text-center max-w-4xl w-full px-4">
        <img
          className="hero-banner__image mb-4"
          src={logo}
          alt="React logo"
        />

        <h1 className="hero-banner__headline font-bold mb-4 text-white text-5xl">
          Strongifier
        </h1>
        <p className="hero-banner__description mb-6 text-white text-xl max-w-3xl">
          Login or Create an account to get <strong>Strongified</strong>.
        </p>

        {/* Button layout */}
        <div className="flex flex-col items-center space-y-4">
          {/* Sign Up Button */}
          <button
            onClick={handleSignUp}
            className="w-64 px-8 py-4 rounded-md bg-white text-red-800 text-2xl font-semibold hover:bg-gray-100 text-center border border-red-800"
          >
            Sign Up
          </button>
          {/* Log In Button */}
          <button
            onClick={handleLogin}
            className="w-64 px-8 py-4 rounded-md bg-red-800 text-white text-2xl font-semibold hover:bg-red-900 text-center border border-white"
          >
            Log In
          </button>
        </div>
      </div>
    </div>
  );
};
