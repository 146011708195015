import React from "react";

interface TimestampViewerProps {
  timestamp?: string; // or null if no timestamp is available
}

/**
 * TimestampViewer converts a UTC ISO timestamp (e.g. 2025-02-14T13:45:00.000Z)
 * into the user’s local date/time. It displays in one of three formats:
 *
 *   1) If the date is "today":
 *        Today <hh:mm AM/PM>
 *
 *   2) Else if within the past 7 days (local time):
 *        <DayOfWeek> <hh:mm AM/PM>
 *
 *   3) Else:
 *        <DayOfWeek>, <YYYY>-<MM>-<DD>, <hh:mm AM/PM>
 *
 * Example outputs:
 *   - "Today 3:21 PM"
 *   - "Sunday 3:21 PM"
 *   - "Sunday, 2025-03-23, 03:21 PM"
 */
export const TimestampViewer: React.FC<TimestampViewerProps> = ({ timestamp }) => {
  // If there's no timestamp, render nothing or a placeholder
  if (!timestamp) {
    return null;
  }

  // Parse the UTC timestamp into a Date object (local time).
  const dateObj = new Date(timestamp);

  // If parsing failed, dateObj will be "Invalid Date", so handle that gracefully.
  if (isNaN(dateObj.getTime())) {
    return <span>(Invalid date)</span>;
  }

  // "Now" in local time
  const now = new Date();

  // Check if the date is "today"
  const sameYear = dateObj.getFullYear() === now.getFullYear();
  const sameMonth = dateObj.getMonth() === now.getMonth();
  const sameDay = dateObj.getDate() === now.getDate();
  const isToday = sameYear && sameMonth && sameDay;

  // Format the time in 12-hour format, e.g. "3:21 PM"
  const timeString = dateObj.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  // If it's today, just show "Today <time>"
  if (isToday) {
    return <span>Today {timeString}</span>;
  }

  // Otherwise, check if it's within the past 7 days (but not today).
  // For that, we calculate how many milliseconds difference from "now" to the given date.
  const diffMs = now.getTime() - dateObj.getTime();
  // 7 days in ms
  const sevenDaysMs = 7 * 24 * 60 * 60 * 1000;

  // If diffMs >= 0 and < sevenDaysMs, we consider it "within 7 days in the past"
  // (If you also want to handle future timestamps, you'd adjust the logic here.)
  const isWithinPastWeek = diffMs >= 0 && diffMs < sevenDaysMs;

  if (isWithinPastWeek) {
    // e.g. "Sunday 3:21 PM"
    const weekday = dateObj.toLocaleString("en-US", { weekday: "long" });
    return <span>{weekday} {timeString}</span>;
  }

  // If older than 7 days, show the long format
  // 1) Day of week: e.g. "Sunday"
  const weekday = dateObj.toLocaleString("en-US", { weekday: "long" });
  // 2) YYYY-MM-DD
  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, "0");
  const day = String(dateObj.getDate()).padStart(2, "0");

  // Combined
  const formatted = `${weekday}, ${year}-${month}-${day}, ${timeString}`;
  return <span>{formatted}</span>;
};
