import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useRef, useState } from "react";
import { CodeSnippet } from "../components/code-snippet";
import { PageLayout } from "../components/page-layout";
import { TimestampViewer } from "../components/timestamp-viewer";

// Updated import to use doChatStreamingV2
import {
  doChatStreamingV2,
  getChatHistoryStreaming,
  clearChatHistory
} from "../services/chat.service";

import { Loader, User, Bot, Trash2 } from "lucide-react";
import TextToHTML from "src/components/text-to-html";
import { EventSourceMessage } from "@microsoft/fetch-event-source"; // Type for SSE events

// ActionButton Component
const ActionButton: React.FC<{
  title: string;
  subtitle: string;
  handleAction: (title: string) => void;
}> = ({ title, subtitle, handleAction }) => (
  <button
    onClick={() => handleAction(title)}
    className="bg-gray-800 text-white p-4 rounded-lg flex flex-col items-start justify-center w-full h-32 transition-colors hover:bg-gray-700"
  >
    <span className="text-lg font-semibold">{title}</span>
    <span className="text-sm text-gray-400">{subtitle}</span>
  </button>
);

function decodeBase64(base64String: string): string {
  try {
    // `atob` decodes standard Base64 in browsers
    return atob(base64String);
  } catch (error) {
    console.error("Error decoding base64:", error);
    return "Error decoding message";
  }
}

// The shape of a single message from the server
interface StrongifierChatMessage {
  chat_source: string;     // e.g. "System", "AI", "Human"
  chat_message: string;    // The text
  chat_timestamp?: string; // e.g. "2025-02-01T12:34:56Z"
}

export const ChatPage: React.FC = () => {
  // We'll store messages as [role, content, timestamp].
  type ChatTuple = [string, string, string | undefined];

  const [chatHistory, setChatHistory] = useState<ChatTuple[]>([]);
  const [message, setMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingChatResponse, setIsLoadingChatResponse] = useState(false);
  const [strongifierInput, setStrongifierInput] = useState("");
  const [partialAiResponse, setPartialAiResponse] = useState<string | null>(null);

  // SSE AbortController
  const streamingControllerRef = useRef<AbortController | null>(null);

  const { getAccessTokenSilently } = useAuth0();

  /****************************************************************
   * 1) The SSE-based chat history loading
   ****************************************************************/
  const loadChatHistory = async () => {
    setIsLoading(true);
    try {
      const accessToken = await getAccessTokenSilently();

      // Abort any existing SSE
      if (streamingControllerRef.current) {
        streamingControllerRef.current.abort();
      }

      // Start streaming the history (this calls `greeting_generator` or similar)
      const controller = getChatHistoryStreaming(
        accessToken,
        handleHistoryMessage,
        handleHistoryError,
        handleHistoryClose
      );
      streamingControllerRef.current = controller;
    } catch (error) {
      setMessage(
        `Error: ${
          error instanceof Error ? error.message : "Unknown error occurred"
        }`
      );
      setIsLoading(false);
    }
  };

  /**
   * SSE Handlers for chat history
   */
  const handleHistoryMessage = (evt: EventSourceMessage) => {
    const { event: evtName, data } = evt;
    const decodedData = decodeBase64(data);

    if (evtName === "update") {
      // Some ephemeral update from the server
      // For v1 compatibility, treat it as an AI message w/o a timestamp
      setChatHistory(prev => [...prev, ["AI", decodedData, undefined]]);
    } else if (evtName === "chat_history") {
      // This is the final array of messages from the server
      try {
        // The final data is a JSON array of objects
        // { role: "Human"/"AI"/..., content: "...", timestamp: "..." }
        // or older style w/ role/content only
        const arr = JSON.parse(decodedData) as Array<
          Partial<StrongifierChatMessage> & { role?: string; content?: string }
        >;

        // Convert to our [role, content, timestamp] format
        const finalHistory = arr.map(item => {
          const source = item.chat_source ?? item.role ?? "AI";
          const message = item.chat_message ?? item.content ?? "";
          const timestamp = item.chat_timestamp ?? undefined;
          return [source, message, timestamp] as ChatTuple;
        });

        setChatHistory(finalHistory);
      } catch (err) {
        console.error("Error parsing chat_history JSON:", err);
      }

      setIsLoading(false);

      // Optionally abort SSE if the server is done
      if (streamingControllerRef.current) {
        streamingControllerRef.current.abort();
      }
    } else {
      // Unknown or no event => treat as an AI update w/o timestamp
      setChatHistory(prev => [...prev, ["AI", decodedData, undefined]]);
    }
  };

  const handleHistoryError = (error: unknown) => {
    console.error("SSE error (history):", error);
    setMessage(`Error loading chat history: ${String(error)}`);
    setIsLoading(false);

    if (streamingControllerRef.current) {
      streamingControllerRef.current.abort();
    }
  };

  const handleHistoryClose = () => {
    console.log("Chat history SSE closed.");
    setIsLoading(false);
  };

  // Load chat history on mount
  useEffect(() => {
    loadChatHistory();
    return () => {
      if (streamingControllerRef.current) {
        streamingControllerRef.current.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Clearing the chat
   */
  const handleClearChat = async () => {
    setChatHistory([]);
    setIsLoading(true);
    try {
      const accessToken = await getAccessTokenSilently();
      const { data, error } = await clearChatHistory(accessToken);
      if (error) {
        setMessage(`Error: ${error.message}`);
      } else {
        await loadChatHistory();
      }
    } catch (error) {
      setMessage(
        `Error: ${
          error instanceof Error ? error.message : "Unknown error occurred"
        }`
      );
      setIsLoading(false);
    }
  };

  /**
   * Submitting a chat message => calls doChatStreamingV2
   */
  const handleStrongifierSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!strongifierInput.trim()) return;

    const inputToSend = strongifierInput.trim();
    setStrongifierInput("");
    handleChatSubmit(inputToSend);
  };

  const handleAction = async (action: string) => {
    handleChatSubmit(action);
  };

/**
 * When submitting a chat message, we create a new message in local state
 * with a UTC timestamp (so that it displays like the server timestamps).
 */
const handleChatSubmit = async (humanMessage: string) => {
  // Create a local UTC ISO timestamp for the user's message
  const nowIso = new Date().toISOString();

  // Add user’s message to local chat with that timestamp
  setChatHistory(prev => [...prev, ["Human", humanMessage, nowIso]]);

  setIsLoadingChatResponse(true);
  setPartialAiResponse(null);

  try {
    if (streamingControllerRef.current) {
      streamingControllerRef.current.abort();
    }
    const accessToken = await getAccessTokenSilently();

    const controller = doChatStreamingV2(
      accessToken,
      humanMessage,
      handleSSEMessage,
      handleSSEError,
      handleSSEClose
    );
    streamingControllerRef.current = controller;
  } catch (error) {
    setMessage(
      `Error: ${
        error instanceof Error ? error.message : "Unknown error occurred"
      }`
    );
    setPartialAiResponse(null);
    setIsLoadingChatResponse(false);
  }
};

  /**
   * SSE message handler for v2 (final or update)
   */
  const handleSSEMessage = (evt: EventSourceMessage) => {
    const { event: evtName, data } = evt;
    const decodedContent = decodeBase64(data);

    // This is one StrongifierChatMessage in JSON
    let parsed: StrongifierChatMessage;
    try {
      parsed = JSON.parse(decodedContent) as StrongifierChatMessage;
    } catch (err) {
      console.error("Error parsing JSON from SSE:", err);
      return;
    }

    const { chat_source, chat_message, chat_timestamp} = parsed;

    if (evtName === "update") {
      // Ephemeral update
      setPartialAiResponse(chat_message);
    } else if (evtName === "chat") {
      // Final AI message
      setPartialAiResponse(null);
      setIsLoadingChatResponse(false);

      // Append to chat with the real timestamp
      setChatHistory(prev => [
        ...prev,
        [chat_source || "AI", chat_message, chat_timestamp]
      ]);

      // Optionally abort SSE
      if (streamingControllerRef.current) {
        streamingControllerRef.current.abort();
      }
    } else {
      // Unknown event => treat as partial
      setPartialAiResponse(chat_message);
    }
  };

  const handleSSEError = (error: unknown) => {
    console.error("SSE error (v2):", error);
    setIsLoadingChatResponse(false);
    if (streamingControllerRef.current) {
      streamingControllerRef.current.abort();
    }
  };

  const handleSSEClose = () => {
    console.log("SSE stream closed by server or aborted (v2).");
  };

  return (
    <PageLayout>
      <div className="flex flex-col items-center justify-between bg-gray-900 p-4 text-white grow text-2xl">
        <div className="flex flex-col items-center grow">
          {/* Waiting / no-chat Logo */}
          {!isLoading && chatHistory.length === 0 ? (
            <div className="mb-12 grow grid grid-cols-3 grid content-center">
              <svg
                className="w-16 h-16"
                viewBox="0 0 100 100"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M50 10 L90 90 L10 90 Z" fill="white" />
              </svg>
            </div>
          ) : null}

          {/* Chat Response Area */}
          {isLoading ? (
            <div className="mb-12 grow grid grid-cols-3 grid content-center">
              <Loader className="w-12 h-12 text-blue-500 animate-spin" />
            </div>
          ) : (
            chatHistory.length > 0 && (
              <div className="mb-12 grow flex flex-col content-top p-6 space-y-4 overflow-y-auto">
                {/* Existing messages */}
                {chatHistory.map(([role, content, timestamp], index) => {
                  const isHuman = role === "Human";
                  return (
                    <div
                      key={index}
                      className={`flex flex-col ${
                        isHuman ? "items-end" : "items-start"
                      }`}
                    >
                      {/* Timestamp line (if present) */}
                      {timestamp && (
                        <div className="text-s text-gray-400 mb-2 mt-4">
                          <TimestampViewer timestamp={timestamp} />
                        </div>
                      )}

                      {/* Bubble */}
                      <div
                        className={`max-w-3/4 ${
                          isHuman
                            ? "bg-blue-100 text-gray-800 rounded-l-lg rounded-br-lg ml-12"
                            : "bg-gray-800 text-white rounded-r-lg rounded-bl-lg mr-12"
                        } p-4 shadow-md`}
                      >
                        <div className="flex items-start space-x-2">
                          {!isHuman && (
                            <Bot className="w-6 h-6 text-blue-500 mt-1 flex-shrink-0" />
                          )}
                          <TextToHTML content={content} />
                          {isHuman && (
                            <User className="w-6 h-6 text-blue-500 mt-1 flex-shrink-0" />
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}

                {/* Partial AI message if loading */}
                {isLoadingChatResponse && partialAiResponse && (
                  <div className="flex flex-col items-start">
                    {/* No timestamp for ephemeral updates */}
                    <div className="bg-gray-800 text-white rounded-r-lg rounded-bl-lg mr-12 p-4 shadow-md max-w-3/4">
                      <div className="flex items-center space-x-2">
                        <Bot className="w-6 h-6 text-blue-500" />
                        <TextToHTML content={partialAiResponse} />
                        <Loader className="w-6 h-6 text-blue-500 animate-spin" />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )
          )}

          {/* Clear Chat Button */}
          {!isLoading && chatHistory.length > 0 ? (
            <button
              onClick={handleClearChat}
              className="mb-6 w-4/5 bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded flex items-center justify-center transition duration-300 ease-in-out"
            >
              <Trash2 className="mr-2" size={20} />
              Clear Chat
            </button>
          ) : null}

          {/* Action Buttons */}
          <div className="grid grid-cols-2 gap-4 mb-12 w-full">
            <ActionButton
              title="If I run a 29 minute 5k, how fast should I be able to run a half marathon in?"
              subtitle="Race Time Prediction"
              handleAction={handleAction}
            />
            <ActionButton
              title="Estimate My Threshold Speed and Heartrate over the past year"
              subtitle="Calculate Benchmark Run Stats"
              handleAction={handleAction}
            />
            <ActionButton
              title="Analyze my cardio zone distribution over the past month"
              subtitle="Understand Running History"
              handleAction={handleAction}
            />
            <ActionButton
              title="What should my next workout be?"
              subtitle="Optimize Training Plan"
              handleAction={handleAction}
            />
          </div>
        </div>

        {/* Message Strongifier */}
        <div className="w-full max-w-2xl mt-auto">
          <form onSubmit={handleStrongifierSubmit} className="flex items-center">
            <input
              type="text"
              value={strongifierInput}
              onChange={(e) => setStrongifierInput(e.target.value)}
              placeholder="Enter a message to Strongifier"
              className="flex-grow p-3 rounded-l-lg bg-gray-800 text-white border-2 border-r-0 border-gray-700 focus:outline-none focus:border-blue-500"
            />
            <button
              type="submit"
              className="bg-blue-500 text-white p-3 rounded-r-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            >
              Send
            </button>
          </form>
        </div>
      </div>
    </PageLayout>
  );
};
