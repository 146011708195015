import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

export const LoginButton: React.FC = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/chat",
      },
      authorizationParams: {
        prompt: "login",
      },
    });
  };

  return (
    <button
      onClick={handleLogin}
      className="w-64 px-8 py-4 rounded-md bg-red-800 text-white text-2xl font-semibold hover:bg-red-900 text-center border border-white"
    >
      Log In
    </button>
  );
};
